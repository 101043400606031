import React, {Component} from 'react';
import {
    Button, Paper, Typography, TextField, Container, Divider, FormControl, InputAdornment, CircularProgress
} from '@mui/material';
import ErrorModal from "./ErrorModal";
import ChatOptionsDialog from './ChatOptionsDialog/ChatOptionsDialog';
import ComposeChatDialog from './ComposeChatDialog/ComposeChatDialog';
import MessageEditDialog from './MessageEditDialog/MessageEditDialog';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const secureURL = process.env.REACT_APP_SECURE_URL;
const secureKey = process.env.REACT_APP_UPLOAD_API_KEY;

const fileTypeMap = {
    "image/jpeg": 'JPEG',
    "image/jpg": 'JPG',
    "image/png": 'PNG',
    "image/gif": 'GIF',
    "image/webp": 'WEBP',
    "video/mp4": 'MP4',
    "video/3gpp": '3GPP',
    "audio/mpeg": 'MPEG',
    "audio/ogg": 'OGG',
    "audio/opus": 'OPUS',
    "application/pdf": 'PDF',
    "application/msword": 'MSWORD',
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": 'WORD',
    "application/vnd.ms-excel": 'EXCEL',
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": 'EXCEL',
    "application/vnd.ms-powerpoint": 'PPT',
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": 'PPT',
    "text/plain": 'TXT',
    "application/rtf": 'RTF',
    "text/csv": 'CSV',
    "text/vcard": 'VCARD',
    "application/zip": 'ZIP',
};

class Chat extends Component {

    isSecretChat = () => {
        if (this.props.chatTask) {
            if ((this.props.chatTask.fields && this.props.chatTask.fields['Secret Task']) ||
                (this.props.chatTask.id && this.props.chatTask.id.startsWith('mem_'))) {
                return true;
            }
        }
        return false;
    };
    
    constructor(props) {
        super(props);
        this.state = {
            chatMessages: [],
            userMessage: '',
            messageData: '',
            userId: this.props.userId,
            familyId: this.props.familyID,
            taskId: this.props.chatTask ? this.props.chatTask.id : null,
            memberId:null,
            isSecretTask: this.isSecretChat(),
            members: this.props.members,
            retryCount: 0,
            isChatOptionsOpen: false,
            isComposeChatOpen: false,
            showOldMessages: false,
            currentOffset: 30,
            selectedMessage: null,
            isMessageEditDialogOpen: false,
            isMessageSending: false,
            isEditingMessage:false,
            editingMessageID:null,
            selectedFile: null,
            errorMessage: null,
            fileTypeFormatted: '',
            fileType: '',
            fileName: '',
            fileSize: '',
            filePreview: null,
            image: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
            document: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'text/plain', 'application/rtf', 'text/csv', 'text/vcard', 'application/zip'],
            video: ['video/mp4', 'video/3gpp'],
            audio: ['audio/mpeg', 'audio/ogg', 'audio/opus'],
        };
        this.inputRef = React.createRef(); 
        this.messageContainerRef = React.createRef();
        this.lastDate = null;
        this.handleMediaOpen = this.handleMediaOpen.bind(this);
    }

    messagesEndRef = React.createRef();

    componentDidMount() {
        this.scrollToBottom();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      
        if (this.props.chatTask !== prevProps.chatTask) {
            this.scrollToBottom();
            let memberId = null;
            if (this.props.chatTask && this.props.chatTask.fields && this.props.chatTask.fields['Member']) {
                memberId = this.props.chatTask.fields['Member'][0];
            }

            if (this.props.chatTask && this.props.chatTask.id.startsWith('mem_')) {
                memberId = this.props.chatTask.id.replace('mem_', ''); 
            }
            this.setState({
                taskId: this.props.chatTask ? this.props.chatTask.id : 'General',
                isSecretTask: this.isSecretChat(),
                members: this.props.members,
                memberId:memberId,
                currentOffset:30,

            }, async () => {
                if (this.props.isWebSocketOpen) {
                    try {
                        await this.fetchChatHistory();
                    } catch (error) {
                        console.error("Error fetching chat history:", error);
                    }
                } else {
                    this.retryFetchChatHistory();
                }
            });
        }

        if (prevProps.chatMessages !== this.props.chatMessages) {
            if (!this.state.showOldMessages) {
                this.scrollToBottom();
            }
        }
        if (!this.props.isWebSocketOpen) {
            console.error("WebSocket is not connected. Attempting to reconnect from chat...");
            this.props.connectWebSocket && this.props.connectWebSocket();
        }
    }

    componentWillUnmount() {

        if (this.props.isWebSocketOpen) {
            this.props.ws.removeEventListener('message', this.handleMessageConfirmation);
        }
    }


    scrollToBottom = () => {
        if (this.messagesEndRef.current) {
            this.messagesEndRef.current.scrollIntoView({behavior: 'auto'});
        }
    };

    fetchChatHistory = async () => {
        if (!this.props.isWebSocketOpen) {
            throw new Error("WebSocket is not open.");
        }
        try {
            await this.requestChatHistory();
            this.sendReadAllAcknowledgement();
            this.scrollToBottom();
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    }

    retryFetchChatHistory = () => {
        if (!this.props.isWebSocketOpen) {
            setTimeout(this.retryFetchChatHistory, 1000);
        } else {
            this.fetchChatHistory()
                .then(() => {
                    this.scrollToBottom();
                })
                .catch(error => {
                    console.error("Error fetching chat history after WebSocket open:", error);
                });
        }
    }

    requestChatHistory = () => {
        return new Promise((resolve, reject) => {

            if (this.props.isWebSocketOpen) {
                const socket = this.props.ws;
                const {taskId, familyId, userId} = this.state;

                const historyRequest = {
                    type: 'get_history', family_id: familyId, user_id: userId, task_id: taskId,
                };

                const handleMessage = (event) => {
                    const data = JSON.parse(event.data);
                    if (data.type === 'chat_history') {
                        socket.removeEventListener('message', handleMessage);
                        resolve(data);
                    }
                };

                socket.addEventListener('message', handleMessage);
                socket.send(JSON.stringify(historyRequest));
            } else {
                console.error("WebSocket is not open. Waiting before retrying...");
                // If not open, wait and try to fetch the chat history again.
                setTimeout(() => this.requestChatHistory().then(resolve).catch(reject), 1000);
            }
        });
    }

    requestOlderChatHistory = () => {

        if (this.props.isWebSocketOpen) {
            const socket = this.props.ws;
            const {taskId, familyId, userId} = this.state;


            const offset = this.state.currentOffset;
            this.setState(prevState => ({currentOffset: prevState.currentOffset + 30}));
            this.setState({showOldMessages: true})

            const historyRequest = {
                type: 'get_older_history', family_id: familyId, user_id: userId, task_id: taskId, offset: offset
            };
            socket.send(JSON.stringify(historyRequest));
        }
    };

    sendReadAllAcknowledgement = () => {
        const managingIDs = this.props.managingIDs;
        const ids = managingIDs.map(item => item.id);

        if (ids.includes(this.state.familyId)) {
            return;
        }

        const all_acknowledgement = {
            type: 'read_all_acknowledgement', family_id: this.state.familyId, task_id: this.state.taskId, is_admin: 1,
        };
        const {ws: socket} = this.props;
        socket.send(JSON.stringify(all_acknowledgement));
    };

    handleFileUpload = (file) => {

        return new Promise(async (resolve, reject) => {


            const formData = new FormData();
            formData.append('file', file);
            formData.append('familyid', this.state.familyId);

            try {

                const response = await fetch(`${secureURL}/fileupload/receiveFile.php`, {
                    method: 'POST', headers: {
                        'Authorization': `Bearer ${secureKey}`
                    }, body: formData,
                })

                const data = await response.json();

                if (data.success) {

                    const filePathBase64 = data.path;
                    const filePath = atob(filePathBase64);
                    const filePreviewForChat = `${secureURL}/fileupload/${filePath}`;

                    console.log('File upload successful');
                    return resolve(filePreviewForChat);
                } else {
                    console.log('File upload failed');
                    reject(new Error('File upload failed'));
                }
            } catch (error) {
                console.error(error);
                reject(error);
            }

        });
    }

    handleEditMessageClick = () => {
        this.isEditMessageSend(this.state.userMessage)
            .then(() => {
                this.setState({ userMessage: '' });
            })
            .catch((error) => {
                console.error("Error editing message:", error);
            });
    }
    
    isEditMessageSend = async(message)=>{
        this.setState({isMessageSending: true});


        try {
            const {ws: socket} = this.props;

            const editMessageData = {
                type: 'edit_message',
                family_id: this.state.familyId,
                task_id:this.state.taskId,
                new_message: message,
                message_id:this.state.editingMessageID
            };

            await socket.send(JSON.stringify(editMessageData));
        

        } catch (error) {
            console.error("Error editing message:", error);
            this.setState({errorMessage: error.message, isMessageSending: false});
        }
        await this.props.handleEditMessage(this.state.editingMessageID,message)
        this.setState({
            isEditingMessage:false,
            editingMessageID:null,
            isMessageSending: false
        },()=>{
        })
    }

    handleMessageConfirmation = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === 'chat_message' && data.message_id === this.state.messageData.message_id) {
            this.props.ws.removeEventListener('message', this.handleMessageConfirmation);
            this.setState({
                showOldMessages: false,
                selectedFile: null,
                fileName: '',
                fileType: '',
                fileSize: '',
                fileTypeForDisplay: '',
                isMessageSending: false,
                messageData: null,
                referencedMessage:null
            }, () => {
                this.fileInput && (this.fileInput.value = null);
                this.scrollToBottom();
            });
        }
    }


    
    sendMessage = async (message) => {
        this.setState({isMessageSending: true});

        try {

            let uploadedFile = null;
            let fileMetadata = null;

            if (this.state.selectedFile) {
                uploadedFile = await this.handleFileUpload(this.state.selectedFile);
                const {fileName, fileType, fileSize} = this.state;
                fileMetadata = JSON.stringify({
                    file_name: fileName, file_type: fileType, file_size: fileSize,
                });
            }

            const messageData = {
                type: 'chat_message',
                user_id: this.state.userId,
                family_id: this.state.familyId,
                task_id: this.state.taskId,
                secret: this.state.isSecretTask,
                message: message,
                referencedMessage:this.state.referencedMessage? this.state.referencedMessage.ID:null,
                uploaded_file: uploadedFile,
                file_metadata: fileMetadata || null,
            };

            if (this.state.memberId != null) {
                messageData.member_id = this.state.memberId;
            }


            this.setState({messageData: messageData}, () => {
                this.props.ws.addEventListener('message', this.handleMessageConfirmation);
                this.props.ws.send(JSON.stringify(messageData));
            });

        } catch (error) {
            console.error("Error sending message:", error);
            this.setState({errorMessage: error.message, isMessageSending: false});
        }
    }


    handleAddIconClick = () => {
        this.setState({isChatOptionsOpen: true});
    }

    handleChatOptionClose = () => {
        this.setState({isChatOptionsOpen: false});
    }

    handleMediaOpen() {
        this.fileInput.accept = 'image/jpeg,image/png,image/gif,image/webp,video/mp4,video/3gpp,audio/mpeg,audio/ogg,audio/opus';
        this.fileInput.click();
    }

    handleDocumentOpen = () => {
        this.fileInput.accept = 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/rtf,text/csv,text/vcard,application/zip';
        this.fileInput.click();
    }
    bytesToSize = (bytes) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    };

    fileTypeFormatter = (fileType) => {
        return fileTypeMap[fileType] || 'FILE';
    };


    openImageInNewTab = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };


    handleFileSelect = (event) => {
        const file = event.target.files[0];
        const fileTypeFormatted = this.fileTypeFormatter(file.type);
        const fileType = file.type;
        const fileName = file ? file.name : '';
        const fileSize = file ? this.bytesToSize(file.size) : '';
        const filePreview = file ? URL.createObjectURL(file) : null;

        this.setState({
            selectedFile: file,
            fileTypeFormatted: fileTypeFormatted,
            fileName: fileName,
            fileType: fileType,
            fileSize: fileSize,
            filePreview: filePreview,
            isComposeChatOpen: true,
            isChatOptionsOpen: false,
        });
    }
    handleCloseComposeChat = () => {
        this.setState({
            isComposeChatOpen: false, selectedFile: null,
        }, () => {
            this.fileInput.value = null;
        });
    }

    handleSelectMessage = (messageData) => {
            this.setState({
                isMessageEditDialogOpen: true, selectedMessage: messageData
            });
    }
    handleCloseMessageEdit = () => {
        this.setState({
            isMessageEditDialogOpen: false
        });
    }

    handleReplyMessage = (message) => {
        this.setState({referencedMessage:message},()=>{
            this.handleCloseMessageEdit()
        }) 
    }

    handleEditMessage = (message) => {
       this.setState({
        userMessage:message.message,
        isEditingMessage:true,
        editingMessageID:message.ID
       },()=>{
        this.handleCloseMessageEdit()
       })
    }
    handleDeleteMessage = (selectedMessageId) => {

        if (this.props.isWebSocketOpen) {

            const deleteMessageData = {
                type: 'delete_message',
                member_id: this.state.userId,
                family_id: this.state.familyId,
                task_id: this.state.taskId,
                message_id: selectedMessageId
            };


            this.props.ws.send(JSON.stringify(deleteMessageData));
            this.props.handleDeleteMessage(selectedMessageId);

            this.setState({
                isMessageEditDialogOpen: false,
            });
            this.scrollToBottom();
        }
    }

    getNameById = (id) => {
        const entry = this.props.staff.find(entry => entry && 'ID' in entry && entry.ID === id);
        return entry ? entry.Name : 'user';
    };
    handleInMessageUrl = (text) => {

        const linkStyles = {
            wordBreak: 'break-all',
          };
          
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const newlineRegex = /(\n)/g;
    
        let parts = [];
        text.split(newlineRegex).forEach((part, index) => {
            if (newlineRegex.test(part)) {
                parts.push(<br key={index} />);
            } else {
                
                let subParts = part.split(urlRegex);
                subParts.forEach((subPart, subIndex) => {
                    if (urlRegex.test(subPart)) {
                        parts.push(<a key={`${index}-${subIndex}`} href={subPart} style={linkStyles} target="_blank" rel="noopener noreferrer">{subPart}</a>);
                    } else {
                        parts.push(<span key={`${index}-${subIndex}`}>{subPart}</span>);
                    }
                });
            }
        });
    
        return parts;
    };
    
    handlePaste = (event) => {

        const items = (event.clipboardData).items;
        for (let item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                const fileTypeFormatted = this.fileTypeFormatter(file.type);
                const fileType = file.type;
                const fileName = file ? file.name : '';
                const fileSize = file ? this.bytesToSize(file.size) : '';
                const filePreview = file ? URL.createObjectURL(file) : null;
                this.setState({
                    selectedFile: file,
                    fileTypeFormatted: fileTypeFormatted,
                    fileName: fileName,
                    fileType: fileType,
                    fileSize: fileSize,
                    filePreview: filePreview,
                    isComposeChatOpen: true,
                });
                break;
            }
        }
    }

    getMessageById = (id) => {
        return this.props.chatMessages.find(message => message.ID === id) || null;
    } 

    render() {

        const {
            chatMessages
        } = this.props;


        const styles = {

            filePreviewDocument: {
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#E0DEDE',
                padding: 6,
                borderRadius: 12,
                width: '100%',
            }, filePreviewDetails: {
                whiteSpace: 'nowrap',
                textAlign: 'left',
                color: 'white',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: 'light',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }, filePreviewFileName: {
                color: 'black', fontSize: 16,
            }, filePreviewFileType: {
                color: '#12121299', fontSize: 12,
            }, filePreviewFileSize: {
                color: '#12121299', fontSize: 12, marginRight: 6,
            },
        };

        const inputStyles = {
            flexGrow: 1, marginRight: '8px',
        };
        const containerStyles = {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '25px',
            margin: '0px 10px 0 10px ',
        };
        const messagesContainerStyles = {
            flexGrow: 1, overflow: 'auto', marginBottom: '10px', padding: '10px', backgroundColor: 'white',
        };
        const messageStyles = {
            maxWidth: '60%', wordWrap: 'break-word', borderRadius: '10px', padding: '10px',
        };
        const messageContentStyles = {
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#777', fontSize: '0.8em'


        };

        const dateStyles = {
            marginLeft: '10px', color: '#999', fontSize: '10px'

        }
        const submitButton = {
            minWidth: '48px',
            minHeight: '48px',
            background: '#2F98A3',
            borderRadius: "50%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: "4px",
            marginRight: "12px",
        }
        const addIcon = {
            marginLeft: '6', marginRight: '12', display: 'flex', alignItems: 'center'
        }

        const referencedMessageStyle ={
            display: 'flex', alignItems: 'center', width: '100%'

        }

        const referencedMessageAttachmentStyles={ 
            width: '100%', 
            borderRadius: '8px', 
            marginTop: '8px', 
            opacity:'0.5',
        }

        const replyAttachmentStyles={ 
            width: '40px', 
            height: '40px', 
            marginRight: '10px', 
            borderRadius: '5px' 
        }




        let lastDate = null;
        return (<>
            <Container maxWidth="xl" style={containerStyles}>
                {/* Overlay for editing mode */}
                {this.state.editingMessageID && (
                    <div style={{
                        position: 'fixed', // Changed from 'absolute' to 'fixed'
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2,
                    }} onClick={e => {
                        e.stopPropagation();
                        this.setState({ editingMessageID: null, isEditingMessage: false, userMessage: '' });
                    }}></div>
                )}


                {this.state.taskId != null ? (<>
                    <div style={{textAlign: 'center'}}>
                        <Typography variant="h6" style={{marginBottom: '16px'}}>
                            {this.state.taskId === 'General' ? "General Chat" : `Chat for the Task - ${this.props.chatTask?.fields?.['Title']}`}
                        </Typography>
                        <Divider style={{marginBottom: '16px'}}/>
                    </div>
                    <Paper elevation={0} style={messagesContainerStyles}>

                       

                        {this.state.isComposeChatOpen && (<ComposeChatDialog
                            isComposeChatOpen={this.state.isComposeChatOpen}
                            onClose={this.handleCloseComposeChat}
                            userMessage={this.state.userMessage}
                            selectedFile={this.state.selectedFile}
                            fileName={this.state.fileName}
                            fileType={this.state.fileType}
                            fileSize={this.state.fileSize}
                            filePreview={this.state.filePreview}
                            fileTypeFormatted={this.state.fileTypeFormatted}
                            sendMessage={this.sendMessage}
                            image={this.state.image}
                            video={this.state.video}
                            document={this.state.document}
                        />)}

                        {this.state.isMessageEditDialogOpen && <MessageEditDialog
                            isMessageEditDialogOpen={this.state.isMessageEditDialogOpen}
                            onClose={this.handleCloseMessageEdit}
                            handleDeleteMessage={this.handleDeleteMessage}
                            message={this.state.selectedMessage}
                            handleReplyMessage={this.handleReplyMessage}
                            handleEditMessage={this.handleEditMessage}
                            isChatArchived={this.props.chatTask?.isArchived}
                        />}

                        {this.props.isWebSocketOpen && (<div onClick={this.requestOlderChatHistory} style={{

                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            marginLeft: 12,
                            marginRight: 12,
                        }}>
                            <div style={{
                                color: 'black',
                                padding: '6px 16px 6px 16px',
                                fontSize: 14,
                                fontFamily: 'Manrope',
                                fontWeight: '500',
                                wordWrap: 'break-word',
                                background: '#EFEEED',
                                borderRadius: 12,
                            }}>Fetch Older Messages
                            </div>
                        </div>)}

                        {chatMessages.map((messageData, index) => {

                            const {member_id, user_id} = messageData;

                            const messageTimestamp = messageData.timestamp;
                            const messageDate = new Date(messageTimestamp * 1000);
                            const messageDay = messageDate.toDateString();

                            let file_type = null;
                            let file_name = null;
                            let file_size = null;

                            if (messageData.file_metadata && messageData.file_metadata !== "undefined") {
                                const fileMetadataObject = JSON.parse(messageData.file_metadata);
                                file_name = fileMetadataObject?.file_name;
                                file_type = fileMetadataObject?.file_type;
                                file_size = fileMetadataObject?.file_size;
                            }

                            let displayDate = null;

                            if (lastDate !== messageDay) {
                                const today = new Date().toDateString();
                                const yesterday = new Date(Date.now() - 86400000).toDateString();

                                if (messageDay === today) {
                                    displayDate = "Today";
                                } else if (messageDay === yesterday) {
                                    displayDate = "Yesterday";
                                } else {
                                    displayDate = messageDate.toDateString().slice(0, 3) + " " + messageDate.getDate() + " " + messageDate.toLocaleString('default', {month: 'short'});
                                }

                                lastDate = messageDay;
                            }

                            const relevantId = String(user_id) === String(this.state.userId) ? user_id : member_id;
                            const isSentByCurrentUser = this.props.staff.some((member) => member.ID === String(user_id));
                            const fileTypeForDisplay = this.fileTypeFormatter(file_type);
                            const messageParts = this.handleInMessageUrl(messageData.message);


                            if (messageData.hidden && isSentByCurrentUser) {

                                return null;
                            }
                            const name = isSentByCurrentUser ? this.getNameById(user_id) || relevantId : this.props.members.find(member => member.ID === relevantId)?.Name || 'User';

                            return (

                                <div key={index}
                                     style={{display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>

                                    {displayDate && (<div
                                        key={index}
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            margin: 'auto',
                                            padding: '6px 16px 16px 16px',
                                            maxWidth: 150,
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '6px 16px 6px 16px',
                                                background: '#EFEEED',
                                                borderRadius: 12,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <div style={{
                                                color: 'rgba(0, 0, 0, 0.70)',
                                                fontSize: 14,
                                                fontFamily: 'Manrope',
                                                fontWeight: '500',
                                                wordWrap: 'break-word'
                                            }}>
                                                {displayDate}
                                            </div>
                                        </div>
                                    </div>)}

                                    <div onClick={(e) => {
                                        if (e.target.tagName.toLowerCase() === 'a') {
                                            return;
                                        }
                                        this.handleSelectMessage(messageData);
                                    }} style={{
                                        display: 'flex',
                                        justifyContent: isSentByCurrentUser ? 'flex-end' : 'flex-start',
                                        margin: '5px',
                                        padding: '10px',
                                        zIndex: messageData.ID === this.state.editingMessageID ? 600 : 1,
                                    }}>
                                        {messageData.emoji && (<div style={{
                                            // position: 'absolute',
                                            top: -20,
                                            left: -20,
                                            padding: 4,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '24px',
                                        }}>
                                            <span>{messageData.emoji}</span>
                                        </div>)}
                                        <div style={{
                                            ...messageStyles,
                                            backgroundColor: isSentByCurrentUser ? '#E1F7FA' : '#EFEEED',
                                        }}>
                                              {messageData.referencedMessage && this.getMessageById(messageData.referencedMessage) && (
                                                <div style={{
                                                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                                    borderRadius: '8px',
                                                    padding: '8px',
                                                    marginBottom: '8px',
                                                    fontSize: '14px',
                                                    width:'90%',
                                                    fontStyle: 'italic'
                                                }}>
                                                    {/* For an attachedmnt in the Referenced Message */}
                                                    {(() => {
                                                                    
                                                                    const referencedMessage = this.getMessageById(messageData.referencedMessage);
                                                                    if (referencedMessage.file_metadata) {
                                                                        
                                                                        const fileMetadata = JSON.parse(referencedMessage.file_metadata);
                                                                        const fileTypeForDisplay = this.fileTypeFormatter(fileMetadata.file_type);

                                                                        if (this.state.image.includes(fileMetadata.file_type)) {
                                                                            return (
                                                                                <img
                                                                                    src={referencedMessage.uploaded_file}
                                                                                    alt={fileMetadata.file_name}
                                                                                    style={referencedMessageAttachmentStyles}
                                                                                />
                                                                            );
                                                                        } else if (this.state.video.includes(fileMetadata.file_type)) {
                                                                            return (
                                                                                <video
                                                                                    controls
                                                                                    src={referencedMessage.uploaded_file}
                                                                                    style={referencedMessageAttachmentStyles}
                                                                                />
                                                                            );
                                                                        } else if (fileTypeForDisplay === 'PDF') {
                                                                            return (
                                                                                <a href={messageData.uploaded_file}
                                                                                    target="_blank" rel="noopener noreferrer"
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        textDecoration: 'none',
                                                                                        color: 'inherit'
                                                                                    }}>
                                                                                    <PictureAsPdfIcon style={{
                                                                                        fontSize: 28,
                                                                                        marginRight: 12
                                                                                    }} />
                                                                                    <span>{fileMetadata.file_name}</span>
                                                                                </a>
                                                                            )
                                                                        } else if (this.state.document.includes(fileMetadata.file_type)) {
                                                                            return (
                                                                                <a
                                                                                    href={referencedMessage.uploaded_file}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        textDecoration: 'none',
                                                                                        color: 'inherit',
                                                                                        marginTop: '8px',
                                                                                        opacity:'0.5' 
                                                                                    }}
                                                                                >
                                                                                    <div style={styles.filePreviewDocument}>
                                                                                        <img
                                                                                            src='./app-icons/document.svg'
                                                                                            alt='Document Icon'
                                                                                            style={{ width: 48, marginRight: 6 }}
                                                                                        />
                                                                                        <div style={styles.filePreviewDetails}>
                                                                                            <span style={styles.filePreviewFileName}>{fileMetadata.file_name}</span>
                                                                                            <div>
                                                                                                <span style={styles.filePreviewFileSize}>{fileMetadata.file_size}</span>
                                                                                                <span style={{ marginRight: 6 }}>&middot;</span>
                                                                                                <span style={styles.filePreviewFileType}>{fileTypeForDisplay}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            );
                                                                        }
                                                                    }
                                                                    return null;
                                                                })()}
                                                                                                                           
                                                    {this.getMessageById(messageData.referencedMessage).message}
                                                    </div>
                                            )}
                                            <div style={messageContentStyles}>
                            <span style={{
                                fontWeight: 'Bold', color: isSentByCurrentUser ? '#009FC2' : '#5b5b5b'
                            }}>{name}</span>

                                            </div>
                                            <div>
                                                {messageData.hidden ? (!isSentByCurrentUser ? (
                                                    <s>{messageParts}</s>) : null) : (<span>{messageParts}</span>)}
                                                {file_type && (<div style={{margin: '6px 0'}}
                                                                    onClick={(e) => e.stopPropagation()}>
                                                    {this.state.image.includes(file_type) && (<div
                                                        onClick={() => this.openImageInNewTab(messageData.uploaded_file)}>
                                                        <img
                                                            style={{
                                                                width: "100%", height: "100%", borderRadius: '10px'
                                                            }}
                                                            src={messageData.uploaded_file}
                                                            alt={file_name}
                                                        />
                                                    </div>)}
                                                    {this.state.video.includes(file_type) && (<div>
                                                        <video
                                                            style={{
                                                                width: "100%", height: "100%", borderRadius: '10px'
                                                            }}
                                                            controls
                                                            src={messageData.uploaded_file}
                                                        />
                                                    </div>)}
                                                    {fileTypeForDisplay === 'PDF' && (
                                                        <a href={messageData.uploaded_file}
                                                           target="_blank" rel="noopener noreferrer"
                                                           style={{
                                                               display: 'flex', textDecoration: 'none', color: 'inherit'
                                                           }}>
                                                            <div style={styles.filePreviewDocument}>
                                                                <PictureAsPdfIcon style={{
                                                                    color: 'black', fontSize: 48, marginRight: 12
                                                                }}/>
                                                                <div
                                                                    style={styles.filePreviewDetails}>
                                      <span
                                          style={styles.filePreviewFileName}>{file_name}</span>
                                                                    <div>
                                        <span
                                            style={styles.filePreviewFileSize}>{file_size}</span>
                                                                        <span
                                                                            style={{marginRight: 6}}>&middot;</span>
                                                                        <span
                                                                            style={styles.filePreviewFileType}>{fileTypeForDisplay}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>)}
                                                    {this.state.document.includes(file_type) && (
                                                        <a href={messageData.uploaded_file}
                                                           target="_blank" rel="noopener noreferrer"
                                                           style={{
                                                               display: 'flex', textDecoration: 'none', color: 'inherit'
                                                           }}>
                                                            <div style={styles.filePreviewDocument}>
                                                                <img src='/app-icons/document.svg'
                                                                     alt='Document Icon' style={{
                                                                    width: 48, marginRight: 6, filter: 'invert(100%)'
                                                                }}/>
                                                                <div
                                                                    style={styles.filePreviewDetails}>
                                      <span
                                          style={styles.filePreviewFileName}>{file_name}</span>
                                                                    <div>
                                        <span
                                            style={styles.filePreviewFileSize}>{file_size}</span>
                                                                        <span
                                                                            style={{marginRight: 6}}>&middot;</span>
                                                                        <span
                                                                            style={styles.filePreviewFileType}>{fileTypeForDisplay}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>)}
                                                </div>)}
                                                <span
                                                    style={dateStyles}>{new Date(messageData.timestamp * 1000).toLocaleTimeString([], {
                                                    hour: '2-digit', minute: '2-digit'
                                                })}

                                                    {isSentByCurrentUser && (<span style={{marginLeft: '2px'}}>
                                {messageData.timestamp && !messageData.timestamp_read ? (<img
                                    alt="Message Sent"
                                    src="/app-icons/msg-sent.svg"
                                    style={{filter: 'invert(50%)'}}

                                />) : (messageData.timestamp_read && (<img
                                    alt="Message Read"
                                    src="/app-icons/msg-read.svg"
                                    style={{filter: 'invert(50%)'}}

                                />))}

                              </span>)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                        })}

                        <div ref={this.messagesEndRef}/>

                        {this.state.errorMessage && <ErrorModal message={this.state.errorMessage}
                                                                onClose={() => this.setState({errorMessage: null})}/>}

                    </Paper>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center',  zIndex: this.state.editingMessageID ? 600 : 1, backgroundColor: this.state.editingMessageID ? 'white' : null }}>
                     {!this.props.chatTask?.isArchived && (<>
                        <ChatOptionsDialog
                            isChatOptionsOpen={this.state.isChatOptionsOpen}
                            onClose={this.handleChatOptionClose}
                            userMessage={this.state.userMessage}
                            handleMediaOpen={this.handleMediaOpen}
                            handleDocumentOpen={this.handleDocumentOpen}
                        />
                        <div style={referencedMessageStyle}>
                            {this.state.referencedMessage && (
                                <div style={{
                                    backgroundColor: '#f0f0f0',
                                    padding: '10px',
                                    margin: '5px 0',
                                    borderRadius: '10px',
                                    width: `${this.inputRef.current.clientWidth - 35}px`,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {(() => {
                                        const referencedMessage = this.getMessageById(this.state.referencedMessage.ID);
                                        if (referencedMessage.file_metadata) {
                                            const fileMetadata = JSON.parse(referencedMessage.file_metadata);
                                            const fileTypeForDisplay = this.fileTypeFormatter(fileMetadata.file_type);

                                            if (this.state.image.includes(fileMetadata.file_type)) {
                                                return (
                                                    <img
                                                        src={referencedMessage.uploaded_file}
                                                        alt={fileMetadata.file_name}
                                                        style={replyAttachmentStyles}
                                                    />
                                                );
                                            } else if (fileTypeForDisplay === 'PDF') {
                                                return (
                                                    <>
                                                        <PictureAsPdfIcon style={{
                                                            fontSize: 28,
                                                            marginRight: 12
                                                        }} />
                                                        <Typography>{fileMetadata.file_name}</Typography>
                                                    </>
                                                )
                                            }else if (this.state.document.includes(fileMetadata.file_type)) {
                                                return (
                                                    <img
                                                        src='./app-icons/document.svg'
                                                        alt='Document Icon'
                                                        style={replyAttachmentStyles}
                                                    />
                                                );
                                            }
                                        }
                                        return null;
                                    })()}
                                        <Typography noWrap>{this.state.referencedMessage.message}</Typography>
                                        <button
                                            style={{ border: 'none', background: 'none', cursor: 'pointer', color: '#333' }}
                                            onClick={() => this.setState({ referencedMessage: null })}
                                        >
                                            <CloseIcon />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div  ref={this.inputRef}  style={{ display: 'flex', alignItems: 'center', width: '100%'}}>

                            <div style={addIcon} onClick={() => this.handleAddIconClick()}>
                                <img alt="Add" src="/app-icons/add.svg" />
                            </div>

                            {this.props.isWebSocketOpen ? (<TextField
                              
                                style={inputStyles}
                                placeholder="Message"
                                value={this.state.userMessage}
                                fullWidth
                                multiline
                                variant='outlined'
                                InputProps={{
                                    endAdornment: this.state.isMessageSending ? (<InputAdornment position="end">
                                        <CircularProgress size={20} style={{ color: '#2F98A3' }} />
                                    </InputAdornment>) : null, style: { color: 'black' }
                                }}
                                inputProps={{
                                    style: { padding: 0 },
                                }}
                                onPaste={this.handlePaste}
                                onChange={(e) => this.setState({ userMessage: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.shiftKey) {

                                        const currentValue = this.state.userMessage;
                                        const selectionStart = e.target.selectionStart;

                                        this.setState({
                                            userMessage: currentValue.substring(0, selectionStart) + '\n' + currentValue.substring(selectionStart),
                                        });

                                        e.preventDefault();
                                    } else if (e.key === 'Enter' && !e.shiftKey) {
                                        if (this.state.userMessage.trim() !== '') {
                                            this.sendMessage(this.state.userMessage).catch((error) => {
                                                console.error("Error sending message:", error);
                                            });
                                            this.setState({ userMessage: '' });
                                        }
                                        e.preventDefault();
                                    }
                                }}
                            />) : (<div style={{
                                ...inputStyles,
                                padding: '18px',
                                width: "100%",
                                color: '#0D0D0D',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                Chat Loading. Please wait.
                            </div>)}

                            <FormControl>
                                <input
                                    type='file'
                                    id='chat-file-input'
                                    style={{ display: 'none' }}
                                    onChange={this.handleFileSelect}
                                    ref={(input) => {
                                        this.fileInput = input;
                                    }}
                                />
                            </FormControl>

                            {this.state.isEditingMessage && this.state.userMessage !== '' ? (
                        <Button
                            style={{
                                ...submitButton,
                                visibility: 'visible',
                                pointerEvents: 'auto',
                                 color:'white'
                            }}
                            type="button"
                            onClick={this.handleEditMessageClick}
                        >
                          <CheckIcon/>
                        </Button>
                    ) : this.state.isEditingMessage && this.state.userMessage === '' ? (
                        
                        <Button
                            style={{
                                ...submitButton,
                                visibility: 'visible',
                                pointerEvents: 'auto',
                                color:'white'
                               
                            }}
                            type="button"
                            onClick={() => {
                                this.setState({ isEditingMessage: false , editingMessageID:null});
                            }}
                        >
                            <CloseIcon/>
                        </Button>
                    ) : (
                            <Button
                                style={submitButton}
                                type="submit"
                                disabled={(!this.props.isWebSocketOpen) || this.state.userMessage.trim() === ''}
                                onClick={() => {
                                    if (this.state.userMessage.trim() !== '') {
                                        this.sendMessage(this.state.userMessage).catch((error) => {
                                            console.error("Error sending message:", error);
                                        });
                                        this.setState({ userMessage: '' });
                                    }
                                }}>
                                <img
                                    alt="Send"
                                    src="/app-icons/send.svg"
                                    style={{
                                        filter: (!this.props.isWebSocketOpen) ? 'grayscale(100%)' : 'none',
                                        opacity: (!this.props.isWebSocketOpen) ? '0.5' : '1',
                                    }}
                                />
                            </Button> )}
                        </div>
                        </>)}
                    </div>

                </>) : "Select a task to chat"}
            </Container>
        </>);
    }
}

export default Chat;