import React, {Component} from 'react'
import {Button} from "@mui/material"
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import PhotoIcon from '@mui/icons-material/Photo';
import DescriptionIcon from '@mui/icons-material/Description';

class ChatOptionsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.isChatOptionsOpen || false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isChatOptionsOpen !== this.props.isChatOptionsOpen) {
            this.setState({open: this.props.isChatOptionsOpen});
        }
    }

    handleClose = () => {
        this.setState({open: false});
        this.props.onClose();
    }


    render() {

        const {open} = this.state;

        const styles = {
            dialogTitle: {
                textAlign: 'center',
                color: 'black',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: '500',
                wordWrap: 'break-word',
                padding: 12,
            }, button: {
                display: 'flex',
                color: "#48484D",
                backgroundColor: "#ffffff",
                fontFamily: 'Manrope',
                fontSize: 16,
                textTransform: "none",
                borderRadius: 12,
                padding: '9px 8px',
                width: '100%',
                marginBottom: 8,
            }, dialogIcon: {
                color: "#48484D", marginRight: 8, width: 20, height: 20,
            },
        }

        return (<>
            <Dialog
                sx={{zIndex: 99999999}}
                open={open} onClose={this.handleClose}
                aria-labelledby="dialog-title" aria-describedby="dialog-description"
                PaperProps={{
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: "20px",
                        backgroundColor: "#F2F2F2",
                        boxShadow: "none",
                        width: "361px",
                        padding: "12px"
                    }
                }}>
                <DialogTitle id="dialog-title" sx={{padding: '0'}} style={styles.dialogTitle}>Options</DialogTitle>
                <DialogContent sx={{padding: '0'}}>

                    <Button onClick={() => this.props.handleMediaOpen()}
                            style={{...styles.button, justifyContent: 'left'}}>
                        <PhotoIcon style={styles.dialogIcon}/>Photo & Video Library
                    </Button>
                    <Button onClick={() => this.props.handleDocumentOpen()}
                            style={{...styles.button, justifyContent: 'left'}}>
                        <DescriptionIcon style={styles.dialogIcon}/>Document
                    </Button>

                    <Button onClick={this.handleClose} style={styles.button}>
                        Cancel
                    </Button>

                </DialogContent>
            </Dialog>
        </>);
    }
}

export default ChatOptionsDialog;