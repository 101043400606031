import React, {Component} from 'react'
import {Button} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

class MessageEditDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.isMessageEditDialogOpen || false, confirmDeleteOpen: false,
        };
    }

    handleConfirmDeleteClick = () => {
        this.setState({confirmDeleteOpen: true});
    }

    handleConfirmDeleteClose = () => {
        this.setState({confirmDeleteOpen: false});
    }

    handleClose = () => {
        this.setState({open: false});
        this.props.onClose();

    }

    onDeleteMessage = (messageId) => {
        this.setState({
            confirmDeleteOpen: false, open: false,
        });
        this.props.handleDeleteMessage(messageId);
    };

    handleReplyClick = () => {
        this.props.handleReplyMessage(this.props.message);
    };
    handleEditClick = () => {
        this.props.handleEditMessage(this.props.message);
    };



    render() {

        const {open, confirmDeleteOpen} = this.state;
        const {message, isChatArchived} = this.props;

        const messageId = message.ID

        const isEditableandDeletable = message.user_id
        const canReply = !message.hidden;

        const buttonsConfig = isChatArchived ? [
            {
                label: 'Cancel',
                onClick: this.handleClose,
                style: { backgroundColor: '#48484D' },
                show: true
            }
        ] : [
            {
                label: 'Delete',
                onClick: this.handleConfirmDeleteClick,
                style: { backgroundColor: '#FF003D' },
                show: isEditableandDeletable
            },
            {
                label: 'Edit',
                onClick: this.handleEditClick,
                style: { backgroundColor: '#48484D' },
                show: isEditableandDeletable
            },
            {
                label: 'Reply',
                onClick: this.handleReplyClick,
                style: { backgroundColor: '#48484D' },
                show: canReply
            },
            {
                label: 'Cancel',
                onClick: this.handleClose,
                style: { backgroundColor: '#48484D' },
                show: true
            }
        ];
   


        const styles = {
            dialogTitle: {
                textAlign: 'center',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: '500',
                wordWrap: 'break-word',
                marginBottom: 12,
                padding: 0,
            },button: {
                display: 'flex',
                color: '#ffffff',
                backgroundColor: '#48484D',
                fontSize: 16,
                textTransform: 'none',
                borderRadius: 12,
                padding: '8px 10px',
                width: '100%',
            },dialogContainer: {
                display: 'flex',
                alignItems: 'center',
                borderRadius: 10,
                padding: 10,
                paddingRight: 100,
                marginBottom: 10,
                fontFamily: 'Manrope',
                cursor: 'pointer',
            }, dialogIcon: {
                color: '#ffffff', marginRight: 8, width: 20, height: 20,
            },
            alertText: {
                textAlign: 'center',
                fontSize: 14,
                padding: '10px',
            }
        }


        return (<>
            <Dialog
                sx={{zIndex: 9999999999}}
                open={open} onClose={this.handleClose}
                aria-labelledby='dialog-title' aria-describedby='dialog-description'
                PaperProps={{
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '20px',
                        backgroundColor: '#E0DEDE',
                        boxShadow: 'none',
                        minWidth: '300px'
                    }
                }}>
                <div style={{padding: '12px'}}>
                    <DialogTitle id='dialog-title' style={styles.dialogTitle}>{isChatArchived ? "Chat is closed" : "Options"}</DialogTitle>
                    <DialogContent sx={{padding: '0', gap: 12}}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 8 }}>

                            {buttonsConfig.map((button, index) => button.show && (
                                <Button
                                    key={index}
                                    onClick={button.onClick}
                                    style={{ ...styles.button, ...button.style }}
                                >
                                    {button.label}
                                </Button>
                            ))}
                            {!canReply && (
                                <div style={styles.alertText}>
                                   The message was deleted by the client and cannot be replied to
                                </div>
                            )}

                        </div>
                    </DialogContent>
                </div>
            </Dialog>
            <Dialog
                sx={{zIndex: 9999999999}}
                open={confirmDeleteOpen} onClose={this.handleConfirmDeleteClose}
                aria-labelledby='second-dialog-title' aria-describedby='second-dialog-description'>
                <DialogTitle id='second-dialog-title' style={{...styles.dialogTitle, marginTop: 12}}>Delete
                    message?</DialogTitle>
                <DialogContent sx={{padding: '0', gap: 12}}>
                    <div style={{display: 'flex', gap: 8, padding: '0 12px', marginBottom: 12}}>
                    <Button onClick={this.handleConfirmDeleteClose}
                                style={{...styles.button, backgroundColor: '#48484D'}}>Cancel</Button>
                        <Button onClick={() => this.onDeleteMessage(messageId)}
                                style={{...styles.button, backgroundColor: '#FF003D'}}>Delete</Button>
                    </div>
                </DialogContent>

            </Dialog>
        </>);
    }
}

export default MessageEditDialog