import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const NavigationHelper = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            navigate(`/FamilySelection/${storedUserId}`);
        }
    }, [navigate]);

    return null;
};

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: '',

        };

        this.handleClientIdChange = this.handleClientIdChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClientIdChange(event) {
        this.setState({userId: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();

        const {userId} = this.state;

        localStorage.setItem('userId', userId);
        this.props.navigate(`/FamilySelection/${userId}`);
    }


    render() {
        const {userId} = this.state;

        return (<div>
            <NavigationHelper/>
            <form onSubmit={this.handleSubmit}>
                <TextField
                    label="User ID"
                    variant="outlined"
                    value={userId}
                    onChange={this.handleClientIdChange}
                    placeholder="Enter your User ID"
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </form>
        </div>);
    }
}

const HomePageWithNavigation = () => {
    const navigate = useNavigate();

    return <HomePage navigate={navigate}/>;
};

export default HomePageWithNavigation;
