import React, {Component} from 'react';
import Chat from './Chat';
import Notification from './Notification'
import Sidebar from './SideBar';
import {Button} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const wssURL = process.env.REACT_APP_WSS_URL;

const NOTIFICATIONS='notifications'


class ChatPage extends Component {
    constructor(props) {

        super(props);
        this.state = {
            isWebSocketOpen: false,
            selectedChat: null,
            chatMessages: [],
            notificationData:[],
            unreadMessageCount: {},
            taskId: null,
            familyMembers: [],
            staff: [],   
        };
        this.ws = null;

        this.connectWebSocket = this.connectWebSocket.bind(this);
    }

    componentDidMount() {
        this.connectWebSocket();
        this.syncStateWithServer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedChat !== prevState.selectedChat) {
            this.setState({
                taskId: this.state.selectedChat ? this.state.selectedChat.id : 'General', chatMessages: [],
            });
        }
    }

    componentWillUnmount() {

        if (this.ws) {
            this.ws.close();
        }
    }

    syncStateWithServer() {
        const {familyID, userId} = this.props;

        fetch(`${baseURL}/utils/pushNotifications.php?action=getUnreadCounts`, {
            method: 'POST', headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }, body: `familyID=${familyID}&memberID=${userId}&is_admin=1`,
        }).then(response => response.json())
            .then(data => {
                this.setState({unreadMessageCount: data.unreadMessageCount});
            })
            .catch(error => {
                console.error('Error fetching unread counts:', error);
            });
    }

    retryCount = 0;
    connectWebSocket = () => {

        if (this.ws === null || this.ws.readyState === WebSocket.CLOSED) {
            const socket = new WebSocket(`${wssURL}`);
            const MAX_RETRIES = 25;
            const PING_INTERVAL = 30000; // Ping every 30 seconds to keep alive

            let pingInterval;

            const sendPing = () => {
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({type: 'ping'}));
                }
            };

            socket.addEventListener('open', () => {

                this.setState({isWebSocketOpen: true});

                const initMessage = {
                    type: 'init_connection', family_id: this.props.familyID,
                };

                socket.send(JSON.stringify(initMessage));
                pingInterval = setInterval(sendPing, PING_INTERVAL);
            });


            socket.addEventListener('close', () => {
                this.setState({isWebSocketOpen: false});
                clearInterval(pingInterval); // Clear the interval on close

                if (this.retryCount < MAX_RETRIES) {
                    console.error('Retrying connection to ws. ' + this.retryCount);
                    this.retryCount++;
                    setTimeout(this.connectWebSocket, 5000);
                } else {
                    console.error('dead conn');
                }
            });


            socket.addEventListener('error', console.error);

            socket.addEventListener('message', (event) => {

                const messageData = JSON.parse(event.data);

                try {

                    switch (messageData.type) {
                        case 'chat_message':
                            this.handleChatMessage(messageData)
                            break;

                        case 'chat_history':
                            this.handleChatHistory(messageData)
                            break;

                        case 'updated_chat_history':
                            this.handleUpdatedChatHistory(messageData);
                            break;

                        case 'admin_mark_as_read':
                            this.handleMarkMessageAsRead(messageData.message_id);
                            break;

                        case 'admin_update_unread_count':
                            this.updateUnreadMessagesCount(messageData);
                            break;
                        case 'notification':
                            this.handleNotification(messageData)
                            break;
                        case 'notification_history':
                            this.handleNotificationHistory(messageData)
                            break;
                        case 'admin_notification_mark_as_read':
                            this.handleMarkNotificationAsRead(messageData.notification_id);
                            break;
                        case 'notification_delete':
                            this.handleNotificationDelete(messageData.notification_id)
                            break;

                        case 'pong':
                        case 'update_unread_count':
                        case 'mark_as_read':
                            break;

                        default:
                            console.error('Unhandled message type:', messageData.type);
                            break;
                    }
                } catch (error) {
                    console.error('Error parsing message:', error);
                }
            });

            this.ws = socket
        }

    }
    updateChatMessages = (messageData) => {

        this.setState(prevState => ({
            chatMessages: [...prevState.chatMessages, messageData]
        }));
    };

    handleChatHistory = (messageData) => {
        try {
            const incomingMessages = messageData.history;
            this.setState(prevState => ({
                chatMessages: [...incomingMessages, ...prevState.chatMessages],
            }));
        } catch (error) {
            console.error("Error parsing chat history:", error);
        }
    };

    handleUpdatedChatHistory = (messageData) => {
        try {
            const incomingMessages = messageData.history;
            this.setState({
                chatMessages: incomingMessages,
            });
        } catch (error) {
            console.error("Error parsing chat history:", error);
        }
    };

    handleNotification = (messageData) => {
        this.updateNotification(messageData);
    };
    handleNotificationHistory = (messageData) => {
        try {
            const incomingNotifications = messageData.notification;
            this.setState({
                notificationData: incomingNotifications,
            });
        } catch (error) {
            console.error("Error parsing chat history:", error);
        } // Fetch the unread counts

    };
    updateNotification = (messageData) => {
        this.setState(prevState => ({
            notificationData: [...prevState.notificationData, messageData]
        }));
    };
    handleNotificationDelete = (notificationId) => {
        const updatedNotifications = this.state.notificationData.filter((notification) => notification.ID !== notificationId);
        this.setState({notificationData: updatedNotifications});
       
    };

    handleChatMessage = (messageData) => {
        if (this.state.taskId === messageData.task_id) {
            this.updateChatMessages(messageData);
            if (messageData.member_id) {
                this.sendReadAcknowledgement(messageData);
            }
        }
        this.syncStateWithServer()
    };

    handleMarkMessageAsRead = (messageID) => {

        let chatMessages = [...this.state.chatMessages];
        let messageIndex = chatMessages.findIndex(message => message.ID === messageID);

        const isMessageFound = messageIndex !== -1;
        if (isMessageFound) {
            chatMessages[messageIndex].timestamp_read = new Date().toISOString();
            this.setState({chatMessages: chatMessages});
        } else {
            console.error('Message ID not found:', messageID);
        }

    };
    handleMarkNotificationAsRead = (NotificationID) => {
        let notificationData = [...this.state.notificationData];
        let notificationIndex = notificationData.findIndex(message => message.ID === NotificationID);

        const isNotificationFound = notificationIndex !== -1;
        if (isNotificationFound) {
            notificationData[notificationIndex].timestamp_read = new Date().toISOString();
            this.setState({notificationData: notificationData});
        } else {
            console.error('Notification ID not found:', NotificationID);
        }

    };

    updateUnreadMessagesCount = (messageData) => {
        this.setState(prevState => {
            let updatedCount;

            updatedCount = {...prevState.unreadMessageCount, [messageData.task_id]: messageData.unread_count};
            return {unreadMessageCount: updatedCount};
        });
    };

    clearUnreadMessagesFromServer = async (taskID) => {
        const {familyID} = this.props;
        try {
            const response = await fetch(`${baseURL}/utils/pushNotifications.php?action=clearUnreadMessages`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }, body: `familyID=${familyID}&taskID=${taskID}&is_admin=1`,
            });

            await response.json();
        } catch (error) {
            console.error('Error clearing unread messages:', error);
        }
    };

    clearTaskBadges = (taskID) => {
        const managingIDs = this.props.managingIDs;
        const ids = managingIDs.map(item => item.id);

        if (ids.includes(this.props.familyID)) {
            return;
        }
        this.setState(prevState => {
            const updatedCount = {...prevState.unreadMessageCount};
            delete updatedCount[taskID];

            this.clearUnreadMessagesFromServer(taskID).catch((error) => {
                console.error("Error clearing messages from server:", error);
            });

            return {unreadMessageCount: updatedCount};
        });
    };

    sendReadAcknowledgement(messageData) {  
        
        const managingIDs = this.props.managingIDs;
        const ids = managingIDs.map(item => item.id);

        if (ids.includes(this.props.familyID)) {
            return;
        }
        const acknowledgement = {
            type: 'read_acknowledgement',
            message_id: messageData.ID,
            family_id: messageData.family_id,
            task_id: messageData.task_id,
            is_admin: 1
        };
        this.ws.send(JSON.stringify(acknowledgement));
    }

    handleDeleteMessage = (messageId) => {
        const updatedMessages = this.state.chatMessages.filter((message) => message.ID !== messageId);
        this.setState({chatMessages: updatedMessages});
    };

    handleEditMessage = (messageId, newMessage) => {
        this.setState((prevState) => {
            const updatedMessages = prevState.chatMessages.map((message) => {
                if (message.ID === messageId) {
                    return { ...message, message: newMessage }; 
                }
                return message; 
            });
            return { chatMessages: updatedMessages }; 
        });
    };

    handleSidebarItemClick = (task, staff) => {
        this.setState({
            selectedChat: task, taskId: task.id, staff: staff

        }, () => {
            this.clearTaskBadges(task.id);
        });

    };
    handleMembersUpdate = (members) => {
        this.setState({familyMembers: members});
    };

    handleBackButtonClick = () => {
        if (this.props.handleCloseChatPage) {
            this.setState({ taskId: null, selectedChat: null }, () => { this.props.handleCloseChatPage(); });
        }
    };


    render() {
        const {familyID, userId} = this.props;
        const {chatMessages, selectedChat,notificationData} = this.state;

        const storedFamilies = localStorage.getItem('Families');
        
        const familyMap = {};

        // Check if storedFamilies is not null before proceeding
        if (storedFamilies) {
            const combinedDataParsed = JSON.parse(storedFamilies);
            const keys = ['families', 'managing', 'covering'];
        
            keys.forEach((key) => {
                if (combinedDataParsed[key]) {
                    combinedDataParsed[key].forEach((family) => {
                        familyMap[family.id] = family.Name;
                    });
                }
            });
        }


        return (<>
            <div style={null}>
    
                    <Button
                        variant="contained"
                        color="primary"

                        style={{
                            background: 'transparent',
                            margin: '5px',
                            textDecoration: 'none',
                            border: 'none',
                            boxShadow: 'none',
                            color: 'black'

                        }}
                        onClick={this.handleBackButtonClick}
                    >
                        <ArrowBackIosIcon/>
                        <div
                            style={{
                                marginRight: 8,
                                width: 40,
                                height: 40,
                                background: '#D74654',
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            {familyMap[familyID] ? familyMap[familyID].slice(0, 3) : 'Fam'}
                        </div>


                        {familyMap[familyID] ? familyMap[familyID] : 'Family'}
                    </Button>
                <div style={{display: 'flex'}}>
                    <Sidebar
                        familyID={familyID}
                        onItemClick={this.handleSidebarItemClick}
                        onMembersUpdate={this.handleMembersUpdate}
                        unreadMessageCount={this.state.unreadMessageCount}
                    />

                    {selectedChat && selectedChat.id === NOTIFICATIONS ? (
                        <Notification
                        familyID={familyID}
                        chatTask={selectedChat}
                        userId={userId}
                        members={this.state.familyMembers}
                        ws={this.ws}
                        notificationData={notificationData}
                        isWebSocketOpen={this.state.isWebSocketOpen}
                        connectWebSocket={this.connectWebSocket}
                        staff={this.state.staff} />
                    ) : (
                        <Chat
                            familyID={familyID}
                            chatTask={selectedChat}
                            userId={userId}
                            members={this.state.familyMembers}
                            ws={this.ws}
                            chatMessages={chatMessages}
                            isWebSocketOpen={this.state.isWebSocketOpen}
                            handleDeleteMessage={this.handleDeleteMessage}
                            handleEditMessage={this.handleEditMessage}
                            connectWebSocket={this.connectWebSocket}
                            staff={this.state.staff}
                            managingIDs={this.props.managingIDs}
                        />
                    )}

                </div>
            </div>
        </>);
    }
}


export default ChatPage;
