import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import FamilySelection from './components/FamilySelection';
import './App.css';
import HomePage from './components/HomePage';
//import Navbar from './components/NavBar';

class App extends Component {
    render() {
        return (<Router>
            {/* <Navbar/> */}
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/FamilySelection/:userId" element={<FamilySelection/>}/>
            </Routes>
        </Router>);
    }
}

export default App;
