import React, {Component} from 'react';
import MessageEditDialog from './MessageEditDialog/MessageEditDialog';
import {
    Button, Paper, Typography, TextField, Container, Divider, FormControl, InputAdornment, CircularProgress,List,ListItem,ListItemText
} from '@mui/material';
import ErrorModal from "./ErrorModal";





class Notification extends Component {

   
    constructor(props) {
        super(props);
        this.state = {
            notificationData: [],
            userMessage: '',
            messageData: '',
            userId: this.props.userId,
            familyId: this.props.familyID,
            taskId: this.props.chatTask ? this.props.chatTask.id : null,
            memberId:null,
            members: this.props.members,
            errorMessage:null,
            isMessageEditing: false,
            retryCount: 0,
            isMessageEditDialogOpen:false,
            showOldMessages: false,
            currentOffset: 30,
            selectedNotificationID:null
           
           
        };
   
    }
    messagesEndRef = React.createRef();

    componentDidMount() {
        this.scrollToBottom();  
        this.fetchNotificationHistory()

    }


    scrollToBottom = () => {
        if (this.messagesEndRef.current) {
            this.messagesEndRef.current.scrollIntoView({behavior: 'auto'});
        }
    };

    fetchNotificationHistory = async () => {
        if (!this.props.isWebSocketOpen) {
            throw new Error("WebSocket is not open.");
        }
        try {
            await this.requestNotification();
            this.scrollToBottom();
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    }

 

    requestNotification = () => {
        return new Promise((resolve, reject) => {

            if (this.props.isWebSocketOpen) {
                const socket = this.props.ws;
                const {familyId} = this.state;

                const notificationRequest = {
                    type: 'get_notification', family_id: familyId,is_admin:1
                };

                socket.send(JSON.stringify(notificationRequest));
            } else {
                console.error("WebSocket is not open. Waiting before retrying...");
                // If not open, wait and try to fetch the chat history again.
                setTimeout(() => this.requestNotification().then(resolve).catch(reject), 1000);
            }
        });
    }




    handleDeleteMessage = (selectedNotificationId) => {

        if (this.props.isWebSocketOpen) {

            const deleteMessageData = {
                type: 'delete_notification',
                family_id: this.state.familyId,
                notification_id: selectedNotificationId
            };

            this.props.ws.send(JSON.stringify(deleteMessageData));

            this.setState({
                isMessageEditDialogOpen: false,
            });
            this.scrollToBottom();
        }
    }


    
    sendNotification = async (message) => {
        this.setState({isMessageSending: true});

        try {

            const notificationData = {
                type: 'notification',
                user_id: this.state.userId,
                family_id: this.state.familyId,
                message: message,
                is_admin:1
            };

           await this.props.ws.send(JSON.stringify(notificationData));
            this.setState({isMessageSending: false});

        } catch (error) {
            console.error("Error sending message:", error);
            this.setState({errorMessage: error.message, isMessageSending: false});
        }
    }
    handleCloseMessageEdit = () => {
        this.setState({
            isMessageEditDialogOpen: false
        });
    }

    handleNotificationClick=(id)=>{
        this.setState({
            selectedNotificationID:id,
            isMessageEditDialogOpen: true
        });
    }
   

 

    getNameById = (id) => {
        const entry = this.props.staff.find(entry => entry && 'ID' in entry && entry.ID === id);
        return entry ? entry.Name : 'user';
    };
    handleInMessageUrl = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const newlineRegex = /(\n)/g;
    
        let parts = [];
        text.split(newlineRegex).forEach((part, index) => {
            if (newlineRegex.test(part)) {
                parts.push(<br key={index} />);
            } else {
                
                let subParts = part.split(urlRegex);
                subParts.forEach((subPart, subIndex) => {
                    if (urlRegex.test(subPart)) {
                        parts.push(<a key={`${index}-${subIndex}`} href={subPart} target="_blank" rel="noopener noreferrer">{subPart}</a>);
                    } else {
                        parts.push(<span key={`${index}-${subIndex}`}>{subPart}</span>);
                    }
                });
            }
        });
    
        return parts;
    };
    
    handlePaste = (event) => {

        const items = (event.clipboardData).items;
        for (let item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                const fileTypeFormatted = this.fileTypeFormatter(file.type);
                const fileType = file.type;
                const fileName = file ? file.name : '';
                const fileSize = file ? this.bytesToSize(file.size) : '';
                const filePreview = file ? URL.createObjectURL(file) : null;
                this.setState({
                    selectedFile: file,
                    fileTypeFormatted: fileTypeFormatted,
                    fileName: fileName,
                    fileType: fileType,
                    fileSize: fileSize,
                    filePreview: filePreview,
                    isComposeChatOpen: true,
                });
                break;
            }
        }
    }
    

    render() {


        const {
            notificationData
        } = this.props;


        const inputStyles = {
            flexGrow: 1, marginRight: '8px',
        };
        const containerStyles = {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '25px',
            margin: '0px 10px 0 10px ',
        };
        const messagesContainerStyles = {
            flexGrow: 1, overflow: 'auto', marginBottom: '10px', padding: '10px', backgroundColor: 'white',
        };
        const submitButton = {
            minWidth: '48px',
            minHeight: '48px',
            background: '#2F98A3',
            borderRadius: "50%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: "4px",
            marginRight: "12px",
        }
        function formatTimestamp(timestamp) {
            const date = new Date(timestamp * 1000);
            const now = new Date();
            
            const diff = now - date;
        
            const hours = Math.floor(diff / (1000 * 60 * 60));
        
            if (hours < 1) {
                return `${Math.floor(diff / (1000 * 60))} minutes ago`;
            } else if (hours < 24) {
                return `${hours} hours ago`;
            } else if (hours < 48) {
                return 'Yesterday';
            } else {
                return date.toDateString().slice(0, 3) + " " + date.getDate() + " " + date.toLocaleString('default', {month: 'short'})
            }
        }

    
        return (<>
        
            <Container maxWidth="xl" style={containerStyles}>
                {this.state.taskId != null ? (<>
                    <div style={{textAlign: 'center'}}>
                        <Typography variant="h6" style={{marginBottom: '16px'}}>
                            {this.state.taskId === 'General' ? "Notifications" : `Chat for the Task - ${this.props.chatTask?.fields?.['Title']}`}
                        </Typography>
                        <Divider style={{marginBottom: '16px'}}/>
                    </div>
                    <Paper elevation={0} style={messagesContainerStyles}>

                        {this.state.isMessageEditDialogOpen && <MessageEditDialog
                            isMessageEditDialogOpen={this.state.isMessageEditDialogOpen}
                            onClose={this.handleCloseMessageEdit}
                            handleDeleteMessage={this.handleDeleteMessage}
                            messageId={this.state.selectedNotificationID}
                            isMessageEditing={this.state.isMessageEditing}
                        />}
                        <List>  
                            {notificationData.map(notification => {
                                let displayDate = formatTimestamp(notification.timestamp)
                                
                                const entry = this.props.staff.find(entry => entry && 'ID' in entry && entry.ID === notification.user_id);
                                return (
                                    <React.Fragment key={notification.ID}>
                                        <Paper onClick={() => this.handleNotificationClick(notification.ID)}
                                            style={{ backgroundColor: 'white', margin: '4px 8px 4px 8px', borderRadius: '4px', padding: '10px' }} elevation={2}>
                                                <span style={{
                                                    fontWeight: 'Bold', color: '#009FC2'
                                                }}>{entry.Name}</span>
                                            <ListItem>
    
                                                <ListItemText primary={<Typography style={{ color: '#777' }}>{notification.message}</Typography>}
                                                    secondary={<Typography style={{ color: '#9e9e9e' }}>{displayDate}</Typography>} />
                                            </ListItem>
                                            {notification.timestamp && !notification.timestamp_read ? (<img
                                    alt="Message Sent"
                                    src="/app-icons/msg-sent.svg"
                                    style={{filter: 'invert(50%)'}}

                                />) : (notification.timestamp_read && (<img
                                    alt="Message Read"
                                    src="/app-icons/msg-read.svg"
                                    style={{filter: 'invert(50%)'}}

                                />))}
                                {notification.is_hidden && <Typography style={{ color: '#9e9e9e' }}>Not showing</Typography>}
                                        </Paper>
                
                                    </React.Fragment>
                                );
                            })}
                        </List>

                        <div ref={this.messagesEndRef}/>

                        {this.state.errorMessage && <ErrorModal message={this.state.errorMessage}
                                                                onClose={() => this.setState({errorMessage: null})}/>}

                    </Paper>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                       
                            {this.props.isWebSocketOpen ? (<TextField
                                style={inputStyles}
                                placeholder="Message"
                                value={this.state.userMessage}
                                fullWidth
                                multiline
                                variant='outlined'
                                InputProps={{
                                    endAdornment: this.state.isMessageSending ? (<InputAdornment position="end">
                                        <CircularProgress size={20} style={{color: '#2F98A3'}}/>
                                    </InputAdornment>) : null, style: {color: 'black'}
                                }}
                                inputProps={{
                                    style: {padding: 0},
                                }}
                                onPaste={this.handlePaste}
                                onChange={(e) => this.setState({userMessage: e.target.value})}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.shiftKey) {
                                       
                                        const currentValue = this.state.userMessage;
                                        const selectionStart = e.target.selectionStart;
                                
                                        this.setState({
                                            userMessage: currentValue.substring(0, selectionStart) + '\n' + currentValue.substring(selectionStart),
                                        });
                                
                                        e.preventDefault();
                                    } else if (e.key === 'Enter' && !e.shiftKey) {
                                        if (this.state.userMessage.trim() !== '') {
                                            this.sendNotification(this.state.userMessage).catch((error) => {
                                                console.error("Error sending message:", error);
                                            });
                                            this.setState({ userMessage: '' });
                                        }
                                        e.preventDefault();
                                    }
                                }}
                            />) : (<>Loading</>)}

                            <FormControl>
                                <input
                                    type='file'
                                    id='chat-file-input'
                                    style={{display: 'none'}}
                                    onChange={this.handleFileSelect}
                                    ref={(input) => {
                                        this.fileInput = input;
                                    }}
                                />
                            </FormControl>

                            <Button
                                style={submitButton}
                                type="submit"
                                disabled={(!this.props.isWebSocketOpen) || this.state.userMessage.trim() === ''}
                                onClick={() => {
                                    if (this.state.userMessage.trim() !== '') {
                                        this.sendNotification(this.state.userMessage).catch((error) => {
                                            console.error("Error sending message:", error);
                                        });
                                        this.setState({userMessage: ''});
                                    }
                                }}>
                                <img
                                    alt="Send"
                                    src="/app-icons/send.svg"
                                    style={{
                                        filter: (!this.props.isWebSocketOpen ) ? 'grayscale(100%)' : 'none',
                                        opacity: (!this.props.isWebSocketOpen ) ? '0.5' : '1',
                                    }}
                                />
                            </Button>
                         </div>

                </>) : "Select a task to chat"}
            </Container>
        </>);
    }
}

export default Notification;